import { Injectable, Inject } from '@angular/core';
import { IConfigStore } from '@howdy/models';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ConfigStoreService {

  host: string;

  constructor(@Inject(DOCUMENT) private document: any) {
    this.host = this.document.location.hostname;
  }

  getConfigStoreValues(): IConfigStore {
    switch (this.host) {
      case 'portal.howdy.care':
        return {
          GlobalAuthSignIn: 'https://auth.worklifebarometer.com/',
          GlobalAuthSignOut: 'https://auth.worklifebarometer.com/Account/SignOut',
          GlobalAuthPasswordReset: 'https://auth.worklifebarometer.com/AuthB2C/PasswordReset'
        };
      case 'wlb-uat-g-my.azurewebsites.net':
        return {
          GlobalAuthSignIn: 'https://auth.worklifebarometer.com/',
          GlobalAuthSignOut: 'https://auth.worklifebarometer.com/Account/SignOut',
          GlobalAuthPasswordReset: 'https://auth.worklifebarometer.com/AuthB2C/PasswordReset'
        };
      default:
        return {
          // GlobalAuth_SignIn: "https://localhost:44301/",
          // GlobalAuth_SignOut: "https://localhost:44301/Account/SignOut",
          // GlobalAuth_PasswordReset: "https://localhost:44301/AuthB2C/PasswordReset"
          GlobalAuthSignIn: 'https://auth.worklifebarometer.com/',
          GlobalAuthSignOut: 'https://auth.worklifebarometer.com/Account/SignOut',
          GlobalAuthPasswordReset: 'https://auth.worklifebarometer.com/AuthB2C/PasswordReset'
        };
    }
  }

}
