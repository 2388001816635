export interface Label {
  Id: number;
  Language: string;
  LanguageId: number;
  Label: string;
  Text: string;
  PartnerId: number;
  CompanyId: number;
}

export enum LanguageCodeToId {
  en = 1045,
  da = 1041,
  sv = 1153
}

export enum LanguageEnum {
  English = 1045,
  Danish = 1041,
  Swedish = 1153,
  Abkhazian = 1000,
  Afar = 1001,
  Afrikaans = 1002,
  Akan = 1003,
  Albanian = 1004,
  Amharic = 1005,
  Arabic = 1006,
  Aragonese = 1007,
  Armenian = 1008,
  Assamese = 1009,
  Avaric = 1010,
  Avestan = 1011,
  Aymara = 1012,
  Azerbaijani = 1013,
  Bambara = 1014,
  Bashkir = 1015,
  Basque = 1016,
  Belarusian = 1017,
  Bengali = 1018,
  Bihari_languages = 1019,
  Bislama = 1020,
  Bokmål_Norwegian = 1021,
  Bosnian = 1022,
  Breton = 1023,
  Bulgarian = 1024,
  Burmese = 1025,
  Spanish = 1026,
  Catalan = 1027,
  Central_Khmer = 1028,
  Chamorro = 1029,
  Chechen = 1030,
  Chewa = 1031,
  Chinese = 1032,
  Chuang = 1033,
  Church_Slavic = 1034,
  Chuvash = 1035,
  Cornish = 1036,
  Corsican = 1037,
  Cree = 1038,
  Croatian = 1039,
  Czech = 1040,
  Dhivehi = 1042,
  Dutch = 1043,
  Dzongkha = 1044,
  Esperanto = 1046,
  Estonian = 1047,
  Ewe = 1048,
  Faroese = 1049,
  Fijian = 1050,
  Finnish = 1051,
  French = 1052,
  Fulah = 1053,
  Gaelic = 1054,
  Galician = 1055,
  Ganda = 1056,
  Georgian = 1057,
  German = 1058,
  Gikuyu = 1059,
  Greek = 1060,
  Greenlandic = 1061,
  Guarani = 1062,
  Gujarati = 1063,
  Haitian = 1064,
  Hausa = 1065,
  Hebrew = 1066,
  Herero = 1067,
  Hindi = 1068,
  Hiri_Motu = 1069,
  Hungarian = 1070,
  Icelandic = 1071,
  Ido = 1072,
  Igbo = 1073,
  Indonesian = 1074,
  Interlingua = 1075,
  Interlingue = 1076,
  Inuktitut = 1077,
  Inupiaq = 1078,
  Irish = 1079,
  Italian = 1080,
  Japanese = 1081,
  Javanese = 1082,
  Kannada = 1083,
  Kanuri = 1084,
  Kashmiri = 1085,
  Kazakh = 1086,
  Kinyarwanda = 1087,
  Kirghiz = 1088,
  Komi = 1089,
  Kongo = 1090,
  Korean = 1091,
  Kuanyama = 1092,
  Kurdish = 1093,
  Lao = 1094,
  Latin = 1095,
  Latvian = 1096,
  Letzeburgesch = 1097,
  Limburgan = 1098,
  Lingala = 1099,
  Lithuanian = 1100,
  Luba_Katanga = 1101,
  Macedonian = 1102,
  Malagasy = 1103,
  Malay = 1104,
  Malayalam = 1105,
  Maltese = 1106,
  Manx = 1107,
  Maori = 1108,
  Marathi = 1109,
  Marshallese = 1110,
  Moldavian = 1111,
  Mongolian = 1112,
  Nauru = 1113,
  Navaho = 1114,
  Ndebele_North = 1115,
  Ndebele_South = 1116,
  Ndonga = 1117,
  Nepali = 1118,
  Northern_Sami = 1119,
  Norwegian = 1120,
  Norwegian_Nynorsk = 1121,
  Nuosu = 1122,
  Occitan = 1123,
  Ojibwa = 1124,
  Oriya = 1125,
  Oromo = 1126,
  Ossetian = 1127,
  Pali = 1128,
  Panjabi = 1129,
  Pashto = 1130,
  Persian = 1131,
  Polish = 1132,
  Portuguese = 1133,
  Quechua = 1134,
  Romansh = 1135,
  Rundi = 1136,
  Russian = 1137,
  Samoan = 1138,
  Sango = 1139,
  Sanskrit = 1140,
  Sardinian = 1141,
  Serbian = 1142,
  Shona = 1143,
  Sindhi = 1144,
  Sinhala = 1145,
  Slovak = 1146,
  Slovenian = 1147,
  Somali = 1148,
  Sotho, Southern = 1149,
  Sundanese = 1150,
  Swahili = 1151,
  Swati = 1152,
  Tagalog = 1154,
  Tahitian = 1155,
  Tajik = 1156,
  Tamil = 1157,
  Tatar = 1158,
  Telugu = 1159,
  Thai = 1160,
  Tibetan = 1161,
  Tigrinya = 1162,
  Tonga = 1163,
  Tsonga = 1164,
  Tswana = 1165,
  Turkish = 1166,
  Turkmen = 1167,
  Twi = 1168,
  Uighur = 1169,
  Ukrainian = 1170,
  Urdu = 1171,
  Uzbek = 1172,
  Venda = 1173,
  Vietnamese = 1174,
  Volapük = 1175,
  Walloon = 1176,
  Welsh = 1177,
  Western_Frisian = 1178,
  Wolof = 1179,
  Xhosa = 1180,
  Yiddish = 1181,
  Yoruba = 1182,
  Zulu = 1183,
  Pilipino = 1185
}
