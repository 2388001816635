import * as cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  of
} from 'rxjs';
import {
  AnswerOption,
  Language,
  MultiLanguageTexts,
  Survey,
  SurveyAnswer,
  SurveyDefinition,
  SurveyFrequencyEnum,
  SurveyLabel,
  SurveyLabels,
  SurveyQuestion,
  SurveyQuestionTypeEnum,
  SurveyStateEnum,
  SurveyTemplate
} from '@howdy/models';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private currentLanguage: string;
  private surveyLabels: SurveyLabels = {};
  private _surveyLabels$: BehaviorSubject<SurveyLabels> = new BehaviorSubject(null);
  private labelsCache: Map<string, object> = new Map();
  private loadingKeys = new Set<string>();

  get surveyLabels$(): Observable<SurveyLabels> {
    return this._surveyLabels$.asObservable().pipe(filter(_ => !!_));
  }

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private translateService: TranslateService,
  ) {
    this.sharedService.onEntityChange$.subscribe(() => this.reloadSurveyLabels(true));
    this.sharedService.onLanguageChange$.subscribe(() => this.reloadSurveyLabels());
  }

  getCompanySurveys(includeQuestions = false, includeSpecialSurveys = false): Observable<Survey[]> {
    if (!this.sharedService.userPreferences.activeCompanyId) {
      return of([]);
    }

    const options = { params: { companyId: `${this.sharedService.userPreferences.activeCompanyId}` } };

    if (includeQuestions) {
      options['params']['includeQuestions'] = true;
    }

    options['params']['includeSpecialSurveys'] = includeSpecialSurveys;

    return this.http.get<Survey[]>('##API##/Survey', options);
  }

  getSurveyById(id: number, enabledLanguages: Language[]): Observable<Survey> {
    return this.http.get<Survey>(`##API##/Survey/${id}`).pipe(map(data => this.mapBackendSurvey(data, enabledLanguages)));
  }

  getSurveyTemplates(enabledLanguages: Language[]): Observable<SurveyTemplate[]> {
    return this.http.get<SurveyDefinition[]>('##API##/Survey/Templates')
      .pipe(
        map((templates) =>
          templates.filter(async template => { // for each template find out whether there are labels in at least one of enabled languages
            for (const language of enabledLanguages) {
              const labels = await this.fetchSurveyLabels(language.code);

              if (labels && Object.keys(labels).some((label: string) => label.indexOf(`${template.Id}`) >= 0)) {
                return true;
              }
            }

            return false;
          }).map(definition => {
            const lang = enabledLanguages.find(language => language.code === this.sharedService.userPreferences.selectedLanguageId);
            const translations = lang ? this.surveyLabels[lang.code] : {};

            return {
              ...definition,
              title: translations[`SurveyDefinition.${definition.Id}.Name`] || definition.Name,
              description: translations[`SurveyDefinition.${definition.Id}.Description`] || `Description missing for ${definition.Name}`,
              imgSrc: `https://dashboard-s3.s3.eu-west-1.amazonaws.com/images/${definition.Id}.jpg`
            };
          })
        )
      );
  }

  getSurveyTemplateById(id: number, enabledLanguages: Language[]): Observable<Survey> {
    return this.http.get<Survey>(`##API##/Survey/Template/${id}`).pipe(map(data => this.mapBackendSurvey(data, enabledLanguages)));
  }

  submitNewSurvey(survey: Survey): Observable<any> {
    return this.http.post(`##API##/Survey/`, this.prepareSubmitData(survey)).pipe(
      switchMap(response =>
        this.http.get(`##API##/cache/invalidate`).pipe(
          map(() => response)
        )
      )
    );
  }

  updateSurvey(survey: Survey) {
    return this.http.post(`##API##/Survey/Update`, this.prepareSubmitData(survey));
  }

  changeSurveysState(surveysIds: number[], state: number) {
    return this.http.post(`##API##/Survey/ChangeState/${state}`, surveysIds);
  }

  deleteSurveys(surveysIds: number[]) {
    return this.http.post(`##API##/Survey/Delete`, surveysIds);
  }

  getSurveyQuestions(surveyId: number) {
    return this.http.get<SurveyQuestion[]>(`##API##/Survey/${surveyId}/Questions`);
  }

  getSurveyAnswers(surveyId: number, questionKey: string, period: string) {
    const companyId = this.sharedService.userPreferences.activeCompanyId;
    const params = {
      companyId: `${companyId}`,
      surveyId: `${surveyId}`,
      departmentId: `${null}`
    };

    if (questionKey) {
      params['questionKey'] = questionKey;
    }

    if (period) {
      params['period'] = period;
    }

    return this.http.get<SurveyAnswer[]>('##API##/Survey/Answers', { params });
  }

  getSurveyQuestionDepartmentAnswers(surveyId: number, questionKey: string, period: string, departmentId: number) {
    const companyId = this.sharedService.userPreferences.activeCompanyId;
    const params = {
      companyId: `${companyId}`,
      surveyId: `${surveyId}`,
      departmentId: `${departmentId}`
    };

    if (questionKey) {
      params['questionKey'] = questionKey;
    }

    if (period) {
      params['period'] = period;
    }

    return this.http.get<SurveyAnswer[]>('##API##/Survey/AnswersWithDepartment', { params });
  }

  async reloadSurveyLabels(entityChanged = false) {
    const languageFromPreferences = this.sharedService.userPreferences.selectedLanguageId;
    const activeCompanyId = this.sharedService.userPreferences.activeCompanyId;
    const languageNotLoaded = !languageFromPreferences;
    const nothingChanged = !entityChanged && this.currentLanguage === languageFromPreferences;

    if (!activeCompanyId || languageNotLoaded || nothingChanged) {
      return;
    }

    this.currentLanguage = languageFromPreferences;
    const surveyLabels = await this.fetchSurveyLabels();

    if (!surveyLabels) {
      return;
    }

    if (!this.translateService.translations[this.currentLanguage]) {
      await this.translateService.onLangChange.pipe(first()).toPromise();
    }

    Object.keys(surveyLabels).forEach(key => this.translateService.set(key, surveyLabels[key], this.currentLanguage));
    this.surveyLabels = { [this.currentLanguage]: (surveyLabels as SurveyLabel) };
    this._surveyLabels$.next({ [this.currentLanguage]: (surveyLabels as SurveyLabel) });
  }

  async fetchSurveyLabels(language: string = null) {
    const targetLanguage = language || this.currentLanguage;
    const cacheKey = `${this.sharedService.userPreferences.activeCompanyId}-${targetLanguage}`;
    let surveyLabels = this.labelsCache.get(cacheKey);

    if (!surveyLabels && !this.loadingKeys.has(cacheKey)) {
      this.loadingKeys.add(cacheKey);

      try {
        surveyLabels = await this.getSurveyL10nLabels(targetLanguage).toPromise();
        this.labelsCache.set(cacheKey, surveyLabels);
      } finally {
        this.loadingKeys.delete(cacheKey);
      }
    }

    return surveyLabels;
  }

  getSurveyL10nLabels(language: string = null) {
    const companyId = this.sharedService.userPreferences.activeCompanyId;
    const options = { params: { companyId: `${companyId}`, languageCode: language || this.currentLanguage } };
    return this.http.get<object>(`##API##/i18n/surveylabels`, options);
  }

  private prepareSubmitData(survey: Survey) {
    const model: Survey = cloneDeep(survey);
    model.CompanyId = this.sharedService.userPreferences.activeCompanyId;

    const selectionRule = { EnabledLanguages: JSON.stringify(model.EnabledLangs) };

    if (survey.Filter.enabled && survey.Filter.dimension && survey.Filter.value) {
      selectionRule['Filters'] = { [survey.Filter.dimension]: survey.Filter.value };
    }

    model.SurveySelectionRule = JSON.stringify(selectionRule);
    model.Questions.splice(0, 0, model.CoverPage);
    model.Questions.push(model.ThankYouPage);
    model.Questions.forEach(question => {
      question.Texts = this.transferMultiLanguageTexts(question.Texts);
      question.AnswerOptions = question.AnswerOptions
        .map(answerOption => ({ ...answerOption, Texts: this.transferMultiLanguageTexts(answerOption.Texts) }));
    });

    return model;
  }

  private transferMultiLanguageTexts(texts: MultiLanguageTexts) {
    return Object.keys(texts).reduce((res, language) => ({
      ...res,
      [texts[language].languageId]: texts[language].text
    }), {});
  }

  private mapBackendSurvey(survey: Survey, enabledLanguages: Language[]): Survey {
    Survey.parseSurveySelectionRule(survey);

    const enabledLangs = this.getSurveyEnabledLanguages(survey, enabledLanguages);

    let questions: SurveyQuestion[] = survey.Questions
      .map(question => this.mapBackendQuestion(question, enabledLangs, survey));

    const bodyQuestion = questions.find(({ StepType }) => StepType === SurveyQuestionTypeEnum.Body);

    if (bodyQuestion) {
      bodyQuestion.Texts = {};
      questions = questions
        .filter(question => question.StepType !== SurveyQuestionTypeEnum.Body || question === bodyQuestion);
    }

    const coverPage = questions[0].StepType === SurveyQuestionTypeEnum.CoverPage ? questions.shift() :
      SurveyQuestion.getBlank(enabledLangs, SurveyQuestionTypeEnum.CoverPage);

    const thankYouPage = questions.length && questions[questions.length - 1].StepType === SurveyQuestionTypeEnum.ThankYou ?
      questions.pop() :
      SurveyQuestion.getBlank(enabledLangs, SurveyQuestionTypeEnum.ThankYou);

    const today = moment.utc().add(1, 'month');
    const startOfNextMonth = [today.year(), today.month(), 1, 4, 0, 0];
    const startOfMonthAfterThat = [today.year(), today.month() + 1, 1, 4, 0, 0];
    const final = {
      ...survey,
      StartDate: moment.utc(survey.StartDate || startOfNextMonth).local(),
      EndDate: moment.utc(survey.EndDate || startOfMonthAfterThat).local(),
      State: survey.State || SurveyStateEnum.Inactive,
      Filter: survey.Filter,
      Frequency: Object.keys(SurveyFrequencyEnum).some(x => +x === survey.Frequency)
        ? survey.Frequency
        : SurveyFrequencyEnum.Monthly,
      EnabledLangs: enabledLangs,
      Questions: questions,
      CoverPage: coverPage,
      ThankYouPage: thankYouPage
    } as Survey;
    Survey.validate(final);
    return final;
  }

  private mapBackendQuestion(question: SurveyQuestion, enabledLangs: Language[], survey: Survey): SurveyQuestion {

    const answerOptions = this.parseAnswerOptions(question, enabledLangs, survey);

    const isQuestion = question.StepType !== SurveyQuestionTypeEnum.ThankYou && question.StepType !== SurveyQuestionTypeEnum.CoverPage;
    const labelKey = `SurveyDefinition.${survey.SurveyDefinitionId}.Step.${question.Key}.${isQuestion ? 'Question' : 'Content'}`;
    const texts = MultiLanguageTexts.new(survey.Labels, enabledLangs, labelKey);

    return {
      ...question,
      Texts: texts,
      AnswerOptions: answerOptions,
      EditingLang: enabledLangs[0].code,
    } as SurveyQuestion;
  }

  private getSurveyEnabledLanguages(survey: Survey, enabledLanguages: Language[]) {
    const surveyEnabledLanguages = survey.EnabledLangs && survey.EnabledLangs.length ?
      survey.EnabledLangs.filter(x => enabledLanguages.some(l => l.id === x.id)) :
      enabledLanguages;

    const isCompanyEnabledLanguage = (language: Language) => enabledLanguages.some(enabledlang => enabledlang.id === language.id);
    const thereAreSurveyLabelsIn = (language: Language) => Object.keys(survey.Labels[language.code] || {})
      .some(label => label.indexOf(`${survey.SurveyDefinitionId}.Step`) >= 0);

    return surveyEnabledLanguages.filter(language => isCompanyEnabledLanguage(language) && thereAreSurveyLabelsIn(language));
  }

  private parseAnswerOptions(question: SurveyQuestion, enabledLangs: Language[], survey: Survey): AnswerOption[] {

    if (question.StepType !== SurveyQuestionTypeEnum.QuestionWithOptionSetSingleSelection || !question.Configuration) {
      return [];
    }

    const answerOptions = JSON.parse(question.Configuration).AnswerOptions;
    return answerOptions
      .filter(({ Value }) => typeof Value === 'number')
      .map(({ LabelKey, Value }) => ({
        LabelKey,
        Value,
        Texts: MultiLanguageTexts.new(
          survey.Labels, enabledLangs, `SurveyDefinition.${survey.SurveyDefinitionId}.Step.${question.Key}.${LabelKey}`)
      }));
  }
}
