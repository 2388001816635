import { ActiveToast, ToastrService } from 'ngx-toastr';
import { CustomLoaderComponent } from '../shared/components/custom-loader/custom-loader.component';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private activeRequests = 0;
  private loader: ActiveToast<CustomLoaderComponent>;
  private oldLoader: ActiveToast<CustomLoaderComponent>;
  private appLoaded = false;

  constructor(
    private toastrService: ToastrService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      take(1)
    ).subscribe(() => {
      this.appLoaded = true;

      if (this.loader) {
        this.loader.toastRef.componentInstance.appLoaded = true;
      }
    });
  }

  public show() {
    this.activeRequests++;

    if (this.activeRequests === 1 && !this.loader) {

      this.destroyOldLoader();

      this.loader = this.toastrService.info(
        null, null, { toastComponent: CustomLoaderComponent, disableTimeOut: true, tapToDismiss: false });

      this.loader.toastRef.componentInstance.appLoaded = this.appLoaded;
    }

    this.loader.toastRef.componentInstance.requestFired();
  }

  public hide() {
    if (this.activeRequests > 0) {
      this.activeRequests--;
      this.loader.toastRef.componentInstance.requestFinished();

      if (this.activeRequests === 0 && this.loader) {
        this.oldLoader = this.loader;
        this.loader = null;

        setTimeout(() => this.destroyOldLoader(), 100);
      }
    }
  }

  private destroyOldLoader() {
    if (this.oldLoader) {
      this.oldLoader.toastRef.close();
      this.oldLoader = null;
    }
  }
}
