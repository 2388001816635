import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'service-disruption-modal',
  templateUrl: './service-disruption-modal.component.html',
  styleUrls: ['./service-disruption-modal.component.scss']
})
export class ServiceDisruptionModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
