import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomLoaderComponent implements OnInit {

  public appLoaded = false;
  public activeRequests = [];
  protected finishedRequests = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() { }

  requestFired() {
    this.activeRequests.push(0);
    this.changeDetectorRef.detectChanges();
  }

  requestFinished() {
    if (this.activeRequests.length) {
      this.finishedRequests.push(this.activeRequests.pop());
      this.changeDetectorRef.detectChanges();
    }
  }

}
