import * as moment from 'moment';
import { Language } from './language.model';

export enum SurveyQuestionTypeEnum {
  CoverPage = 1,
  QuestionWithOptionSetSingleSelection = 2,
  QuestionWithText = 3,
  Body = 4,
  ThankYou = 99,
}

export enum SurveyStateEnum {
  Draft = 1,
  Active = 2,
  Inactive = 3,
}

export enum SurveyFrequencyEnum {
  Continuous,
  EveryOtherWeek,
  Monthly,
  Quarterly
}

export const QUESTION_ICONS = {
  [SurveyQuestionTypeEnum.CoverPage]: 'far fa-file-alt',
  [SurveyQuestionTypeEnum.QuestionWithText]: 'fas fa-pencil-alt',
  [SurveyQuestionTypeEnum.QuestionWithOptionSetSingleSelection]: 'far fa-check-circle',
  [SurveyQuestionTypeEnum.Body]: 'fa fa-male',
  [SurveyQuestionTypeEnum.ThankYou]: 'far fa-handshake'
};

export const BODY_COLORS = [
  '#c8e1ea',
  '#8dbc32',
  '#c6d729',
  '#eae71e',
  '#fbf30c',
  '#feda20',
  '#ffba44',
  '#fea83f',
  '#f07e2d',
  '#ef582a',
  '#eb312e'
];

export abstract class SurveyFilter {
  dimension?: string;
  value?: string;
  enabled?: boolean;
  static validate = (filter: SurveyFilter) => !filter.enabled || !!(filter.dimension && filter.value);
}

export abstract class SurveyQuestion {
  Key?: string;
  Texts?: MultiLanguageTexts;
  StepType: SurveyQuestionTypeEnum;
  Order?: number;
  AnswerOptions?: AnswerOption[];
  EditingLang?: string;
  Valid?: boolean;
  Deleted?: boolean;
  Answers?: SurveyAnswer[];
  Configuration?: string;
  HasAnswers?: boolean;
  static validate = (question: SurveyQuestion): boolean => {
    const allAnswerOptionsDefined = (languageCode: string) => (question.AnswerOptions || [])
      .filter(ao => !ao.Deleted).every((option: AnswerOption) => !!option.Texts[languageCode].text);

    question.Valid = Object.keys(question.Texts)
      .every(languageCode => !!question.Texts[languageCode].text && allAnswerOptionsDefined(languageCode));

    return question.Valid;
  };

  static addAnswerOption = (question: SurveyQuestion, languages: Language[], focus = false) => {
    question.AnswerOptions.push({
      Texts: MultiLanguageTexts.new(null, languages, ''),
      Editing: focus
    });
  };

  static removeAnswerOption = (question: SurveyQuestion, index: number) => question.AnswerOptions.splice(index, 1);

  static removeLanguage = (question: SurveyQuestion, language: Language, defaultLanguage: Language) => {
    delete question.Texts[language.code];
    (question.AnswerOptions || []).forEach(ao => delete ao.Texts[language.code]);

    if (question.EditingLang === language.code) {
      question.EditingLang = defaultLanguage?.code;
    }
  };

  static addLanguage = (survey: Survey, question: SurveyQuestion, language: Language) => {
    const labels = survey.Labels && survey.Labels[language.code] || {};

    const isQuestion = question.StepType !== SurveyQuestionTypeEnum.ThankYou && question.StepType !== SurveyQuestionTypeEnum.CoverPage;
    const questionLabelKey = `SurveyDefinition.${survey.SurveyDefinitionId}.Step.${question.Key}.${isQuestion ? 'Question' : 'Content'}`;

    question.Texts[language.code] = { languageId: language.id, text: labels[questionLabelKey] };

    if (question.StepType === SurveyQuestionTypeEnum.QuestionWithOptionSetSingleSelection) {
      question.AnswerOptions.forEach(answerOption => {
        const aoLabelKey = `SurveyDefinition.${survey.SurveyDefinitionId}.Step.${question.Key}.${answerOption.LabelKey}`;
        answerOption.Texts[language.code] = { languageId: language.id, text: labels[aoLabelKey] };
      });
    }

    question.EditingLang = language.code;
  };

  static getBlank = (enabledLanguages: Language[], type: SurveyQuestionTypeEnum) => ({
    Texts: MultiLanguageTexts.new(null, enabledLanguages || [], ''),
    AnswerOptions: [],
    StepType: type,
    EditingLang: enabledLanguages[0]?.code
  });

  static parseConfiguration(configuration: string): AnswerOption[] {
    return JSON.parse(configuration).AnswerOptions.filter(({ Value }) => typeof Value === 'number');
  }
}

export abstract class Survey {
  Id?: number;
  Name: string;
  CompanyId?: number;
  SurveyDefinitionId?: number;
  StartDate: Date | moment.Moment;
  EndDate: Date | moment.Moment;
  State: SurveyStateEnum;
  Questions: SurveyQuestion[];
  Filter?: SurveyFilter;
  Valid?: boolean;
  Frequency: SurveyFrequencyEnum;
  EnabledLangs?: Language[];
  SurveySelectionRule?: string;
  CoverPage: SurveyQuestion;
  ThankYouPage: SurveyQuestion;
  Period?: string;
  Labels?: SurveyLabels;

  static addBlankQuestion = (survey: Survey, type: SurveyQuestionTypeEnum) => {
    const question = SurveyQuestion.getBlank(survey.EnabledLangs, type);
    survey.Questions.push(question);
    return question;
  };

  static removeQuestion = (survey: Survey, question: SurveyQuestion) => {
    survey.Questions = survey.Questions.filter(q => q !== question);
  };

  static getBlank = () => {
    const monthFromNow = moment.utc().add(1, 'month');
    const isLastMonthFromNow = monthFromNow.month() === 11;
    const endDateYear = isLastMonthFromNow ? monthFromNow.year() + 1 : monthFromNow.year();
    const endDateMonth = isLastMonthFromNow ? 0 : monthFromNow.month() + 1;

    return {
      Name: '',
      StartDate: moment.utc([monthFromNow.year(), monthFromNow.month(), 1, 4, 0, 0]).local(),
      EndDate: moment.utc([endDateYear, endDateMonth, 1, 4, 0, 0]).local(),
      Questions: [],
      State: SurveyStateEnum.Active,
      Filter: {},
      Frequency: SurveyFrequencyEnum.Quarterly,
      EnabledLangs: [],
      CoverPage: SurveyQuestion.getBlank([], SurveyQuestionTypeEnum.CoverPage),
      ThankYouPage: SurveyQuestion.getBlank([], SurveyQuestionTypeEnum.ThankYou)
    };
  };

  static addLanguage = (survey: Survey, language: Language) => {
    survey.EnabledLangs.push(language);
    [survey.CoverPage, ...survey.Questions, survey.ThankYouPage]
      .forEach(question => SurveyQuestion.addLanguage(survey, question, language));
  };

  static removeLanguage = (survey: Survey, language: Language) => {
    survey.EnabledLangs = survey.EnabledLangs.filter(l => l.code !== language.code);
    [survey.CoverPage, ...survey.Questions, survey.ThankYouPage]
      .forEach(question => SurveyQuestion.removeLanguage(question, language, survey.EnabledLangs[0]));
  };

  static validateQuestions = (survey: Survey) => {
    const questions = survey.Questions.filter(q => !q.Deleted);
    return [survey.CoverPage, ...questions].every(SurveyQuestion.validate);
  };

  static validateFilter = (survey: Survey) => SurveyFilter.validate(survey.Filter);

  static validateFields = (survey: Survey, fields: string[]) => fields.every(field => !!survey[field]) && !!survey.EnabledLangs.length;

  static validate = (survey: Survey) => {
    const questionsValid = Survey.validateQuestions(survey);
    const filterValid = Survey.validateFilter(survey);
    const requiredFieldsValid = Survey.validateFields(survey, ['Name', 'StartDate', 'EndDate']);
    survey.Valid = requiredFieldsValid && filterValid && questionsValid;
    return survey.Valid;
  };

  static parseSurveySelectionRule = (survey: Survey) => {
    if (survey.Filter || survey.EnabledLangs) {
      return;
    }

    const surveySelectionRule = JSON.parse(survey.SurveySelectionRule || null);

    survey.Filter = surveySelectionRule && surveySelectionRule['Filters'] ? {
      dimension: Object.keys(surveySelectionRule['Filters'])[0],
      value: surveySelectionRule['Filters'][Object.keys(surveySelectionRule['Filters'])[0]],
      enabled: true
    } : {};

    survey.EnabledLangs = surveySelectionRule &&
      surveySelectionRule['EnabledLanguages'] &&
      JSON.parse(surveySelectionRule['EnabledLanguages']) ||
      [];
  };
}

export interface SurveyDefinition {
  Id: number;
  Name: string;
}

export interface SurveyTemplate extends SurveyDefinition {
  title: string;
  description: string;
  imgSrc: string;
}

export interface AnswerOption {
  Deleted?: boolean;
  LabelKey?: string;
  Value?: number;
  Texts?: MultiLanguageTexts;
  Editing?: boolean;
}

export abstract class MultiLanguageTexts {
  [key: string]: LanguageText;

  static new = (labels: SurveyLabels, languages: Language[], labelKey: string) =>
    languages.reduce((texts, language) => ({
      ...texts,
      [language.code]: {
        languageId: language.id,
        text: labels ? labels[language.code][labelKey] : ''
      }
    }), {});
}

export interface LanguageText {
  languageId: number;
  text: string;
  valid?: boolean;
}

export interface SurveyLabels {
  [key: string]: SurveyLabel;
}

export interface SurveyLabel {
  [key: string]: string;
}

export interface SurveyAnswer {
  Time: moment.Moment;
  Answer: string;
  QuestionKey: string;
  Period: string;
  Count: number;
  IsBelowAnonymity: boolean;
  DepartmentName?: string;
  SurveyId: number;
  CommentInfo?: CommentInfo;
  FormattedRecipients?: string;
  RecipientsArray?: string[];
}

interface CommentInfo {
  Author: string;
  ManagerRecipients: Recipient[];
  HrRecipients: Recipient[];
  SingledOutHr: boolean;
}

interface Recipient {
  Id: number;
  Fullname: string;
  IsActive: boolean;
}
