export enum TargetPeriods {
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year'
}

export enum UniquePeriods {
  Unique = 'Unique',
  All = 'All',
  Simple = 'Simple',
  Detailed = 'Detailed'
}

export enum MonthPeriods {
  CurrentMonth = 'CurrentMonth',
  LastMonth = 'LastMonth'
}

export const TargetPeriodsValue: { [K in TargetPeriods]?: string } = {
  [TargetPeriods.Month]: '1',
  [TargetPeriods.Quarter]: '3',
  [TargetPeriods.Year]: '12',
};

export const UniquePeriodsValue: { [K in UniquePeriods]?: string } = {
  [UniquePeriods.Unique]: 'Unique',
  [UniquePeriods.All]: 'All',
  [UniquePeriods.Simple]: 'Simple',
  [UniquePeriods.Detailed]: 'Detailed'
};

export const MonthPeriodsValue: { [K in MonthPeriods]?: string } = {
  [MonthPeriods.CurrentMonth]: 'CurrentMonth',
  [MonthPeriods.LastMonth]: 'LastMonth'
};
