import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { CanActivate, CanLoad, RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ConfigStoreService } from './config-store.service';
import { PermissionService } from './permission.service';
import { first, filter } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { UserPermissions, UserPreferences, ResponseCenter, Company } from '@howdy/models';
import { RaygunService } from './raygun.service';

interface PagesPermissions {
  [key: string]: boolean | number;
}

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {

  private pagesPermissions: PagesPermissions;
  private pagesPermissions$: BehaviorSubject<PagesPermissions> = new BehaviorSubject<PagesPermissions>(null);
  private failedUrls: string[] = [];
  private mainBranches = [{
    url: '/pages/dashboard',
    entities: 'Companies',
    preference: 'activeCompanyId'
  }, {
    url: '/responsecenter/incidents',
    entities: 'ResponseCenters',
    preference: 'activeResponseCenterId'
  }];

  constructor(
    private tokenService: TokenService,
    private configStore: ConfigStoreService,
    private permissionService: PermissionService,
    private sharedService: SharedService,
    private router: Router,
    private raygun: RaygunService
  ) {
    combineLatest([
      this.permissionService.userPermissions$,
      this.sharedService.userPreferences$
    ]).subscribe((params) => this.setPagesPermissions(...params));
  }

  async canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.split('/').length < 3) {
      return false;
    }

    if (!this.tokenService.isValid()) {
      window.location.href = this.configStore.getConfigStoreValues().GlobalAuthSignIn;
      return false;
    }

    let permissions = this.pagesPermissions;
    if (!permissions) {
      permissions = await this.pagesPermissions$.asObservable().pipe(filter(x => !!x), first()).toPromise();
    }

    const url = state.url.split('/').slice(0, 3).join('/');
    const isAllowed = !!permissions[url];

    if (!isAllowed) {
      this.logToRaygun(url);
      const triedToFixPreferences = this.tryToFixPreferences(url);

      if (!triedToFixPreferences) {
        this.tryRedirect(url);
      }
    } else {
      this.failedUrls = [];
    }

    return isAllowed;
  }

  canLoad(): boolean {
    if (!this.tokenService.isValid()) {
      window.location.href = this.configStore.getConfigStoreValues().GlobalAuthSignIn;
      return false;
    }
    return true;
  }

  private setPagesPermissions(permissions: UserPermissions, preferences: UserPreferences) {
    this.pagesPermissions = {
      '/pages/dashboard': !preferences.activeResponseCenterId && preferences.activeCompanyId,
      '/pages/company': preferences.activeCompanyId && (permissions.hasCompanyAdminRights || permissions.hasHowdyEmployeeRights),
      '/pages/employee': preferences.activeCompanyId && !permissions.hasOnlyObserverRights,
      '/responsecenter/incidents': preferences.activeResponseCenterId,
      '/pages/report': preferences.activeCompanyId && permissions.hasCompanyAdminRights,
      '/pages/user': permissions.hasUserAdminRights || permissions.hasHowdyEmployeeRights,
      '/pages/feedback': preferences.activeCompanyId && (permissions.hasCompanyAdminRights || permissions.hasHowdyEmployeeRights),
      '/pages/resources': !preferences.activeResponseCenterId && preferences.activeCompanyId,
      '/pages/admin': permissions.hasSystemAdminRights || permissions.hasHowdyEmployeeRights
    };
    this.pagesPermissions$.next(this.pagesPermissions);
  }

  private isTheSameBranch = (a: string, b: string) => a.split('/')[1] === b.split('/')[1];

  private tryToFixPreferences(invalidUrl: string) {
    if (this.isTheSameBranch(invalidUrl, this.router.url)) {
      return false;
    }

    const userProfile = this.sharedService.userProfile;
    const requestedBranch = this.mainBranches.find(
      x => this.isTheSameBranch(x.url, invalidUrl) && (userProfile[x.entities] as any[]).length > 0);
    const otherBranch = this.mainBranches.find(x => !this.isTheSameBranch(x.url, invalidUrl));

    if (requestedBranch) {
      this.tryAgain(requestedBranch, otherBranch, invalidUrl);
      return true;
    }
    return false;
  }

  private tryRedirect(invalidUrl: string) {
    this.failedUrls.push(invalidUrl);

    if (this.isTheSameBranch(invalidUrl, this.router.url)) {
      // if on the same branch, redirect to 'base' url
      const baseUrl = this.mainBranches.map(x => x.url).find(x => this.isTheSameBranch(x, invalidUrl));

      if (this.failedUrls.indexOf(baseUrl) === -1) {
        this.router.navigate([ baseUrl ]);
      }

      return;
    }

    const currentBranch = this.mainBranches.find(x => this.isTheSameBranch(x.url, this.router.url));

    if (currentBranch && this.failedUrls.indexOf(currentBranch.url) === -1) {
      // at this point, guard has already tried to fix preferences to other url, failed and there is no access
      // so if there is current active branch, stay there
      this.router.navigate([ currentBranch.url ]);
      return;
    }

    // otherwise user is coming to the dashboard for the first time and guard
    // is trying any combination of branch and preferences to get him in
    const userProfile = this.sharedService.userProfile;
    const targetBranch = this.mainBranches.find(x => this.failedUrls.indexOf(x.url) === -1 && (userProfile[x.entities] as []).length > 0);
    const otherBranch = this.mainBranches.find(x => targetBranch.url !== x.url);

    if (targetBranch) {
      this.tryAgain(targetBranch, otherBranch, targetBranch.url);
    }
  }

  private tryAgain(branch, invalidBranch, url: string) {
    const userProfile = this.sharedService.userProfile;
    const newEntity: Company | ResponseCenter = userProfile[branch.entities][0];

    const userPreferences = this.sharedService.userPreferences;
    userPreferences[branch.preference] = newEntity.Id;
    userPreferences[invalidBranch.preference] = null;
    userPreferences.activeEntityName = newEntity.Name;

    this.sharedService.setUserPreferences(userPreferences);
    this.router.navigate([url]);
  }

  private logToRaygun(url: string) {
    const userPreferences = this.sharedService.userPreferences;
    const email = this.sharedService.userProfile.Me.Email;
    const message = `User ${email} with preferences ${JSON.stringify(userPreferences)} was denied access to url: ${url}`;
    this.raygun.send(new Error(message));
  }
}
