import { Injectable } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { take, filter, map } from 'rxjs/operators';
import * as rg4js from 'raygun4js';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IMe, Setting } from '@howdy/models';

@Injectable({
  providedIn: 'root'
})
export class RaygunService {

  loggerActive = false;
  raygunExcludedCompanies: number[];

  constructor(private sharedService: SharedService, private http: HttpClient) {
    this.sharedService.userProfile$.pipe(
      filter(({ Me }) => !!Me.Raygun),
      map(({ Me }) => Me),
      take(1)
    ).subscribe((user: IMe) => this.setupRaygun(user));
  }

  async setRaygunExcludedCompanies() {
    const settings = await this.getRaygunSettings();
    this.raygunExcludedCompanies = settings.filter(setting => !setting.BoolValue).map(setting => setting.CompanyId);
  }

  send(error: Error | HttpErrorResponse) {
    const activeCompany = this.sharedService.userPreferences.activeCompanyId;
    const excludeCompany = this.raygunExcludedCompanies?.includes(activeCompany);

    if (this.loggerActive && navigator.onLine && !excludeCompany) {
      rg4js('send', { error });
    }
  }

  getRaygunSettings() {
    return this.http.get<Setting[]>(`##API##/settings/raygun`).toPromise();
  }

  private async setupRaygun(user: IMe) {
    const VERSION_NUMBER = '1.0.0.0';
    rg4js('apiKey', user.Raygun);
    rg4js('setVersion', VERSION_NUMBER);
    rg4js('enableCrashReporting', true);
    rg4js('options', {
      allowInsecureSubmissions: true,
      captureUnhandledRejections: true,
      captureMissingRequests: true
    });
    rg4js('onBeforeSend', (payload) => {
      console.log('Logging error to raygun');
      return payload;
    });
    this.setUser(user);
    this.loggerActive = true;

    if (!this.raygunExcludedCompanies) {
      await this.setRaygunExcludedCompanies();
    }
  }

  private setUser(user: IMe) {
    rg4js('setUser', {
      identifier: user.Email,
      isAnonymous: false,
      email: user.Email,
      firstName: user.Firstname,
      fullName: `${user.Firstname} ${user.Lastname}`
    });
  }
}
