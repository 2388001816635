import { Moment } from 'moment';
import { Language } from './language.model';

export interface IncidentPrediction {
  DepartmentName: string;
  IncidentDate: Date;
  IncidentDetails: any;
  ManagerName: string;
  DepartmentMonthMetricModel: DepartmentMetrics[];
}

export interface DepartmentMetrics {
  MonthStart: Date;
  Colour: string;
}

export interface CompanySettingsLanguage {
  Id: number;
  EnglishName: string;
  Enabled: boolean;
  InUseByNo: number;
}

export interface SurveySetting {
  Id: number;
  Name: string;
  Enabled: boolean;
  StartDate?: string | Moment;
  EndDate?: string | Moment;
  Frequency?: number;
}

export abstract class CompanySettingsDimension {
  Code: string;
  Enabled?: boolean;
  Required?: boolean;
  Labels: { [key: number]: string };
  isNew?: boolean;
  isDone?: boolean;
  isValid?: boolean;
  static getBlank = () => ({
    Code: '',
    Labels: {},
    isNew: true
  } as CompanySettingsDimension);
}

export interface CompanyDimensionsLabelsStructure {
  [key: string]: {
    [languageId: number]: string;
  };
};
