import {
  ErrorHandler,
  Inject,
  Injectable,
  Injector,
  NgZone
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { RaygunService } from './raygun.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from './token.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    private ngZone: NgZone,
    private translateService: TranslateService,
    private raygun: RaygunService,
    private tokenService: TokenService,
    @Inject(Injector) private injector: Injector
  ) { }

  handleError(error: Error | HttpErrorResponse) {
    if (!navigator.onLine && this.tokenService.getToken()) {
      this.ngZone.run(() => {
        this._toastrService.error(
          this.translateService.instant('Portal.Common.Message.NoInternet'),
          null,
          { closeButton: true, disableTimeOut: true, tapToDismiss: false, enableHtml: true });
      });
    }

    this.raygun.send(error);

    console.error(error);
  }

  // Note: get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get _toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }
}
