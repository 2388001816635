
export interface Setting {
  Id: number;
  Level: SettingLevelEnum;
  Label: string;
  PartnerId: number | null;
  CompanyId: number | null;
  PersonId: number | null;
  StringValue: string;
  BoolValue: boolean | null;
  IntValue: number | null;
  DecimalValue: number | null;
  Type: SettingTypeEnum;
}

export enum SettingLevelEnum {
  Global = 0,
  Partner = 10,
  Company = 20,
  Person = 30
}

export enum SettingTypeEnum {
  StringValue = 1,
  BoolValue = 2,
  IntValue = 3,
  DecimalValue = 4
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SettingLevelEnum {

  export function values() {
    return Object.keys(SettingLevelEnum).filter(
      (type) => isNaN(type as any) && type !== 'values'
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SettingTypeEnum {

  export function values() {
    return Object.keys(SettingTypeEnum).filter(
      (type) => isNaN(type as any) && type !== 'values'
    );
  }
}
