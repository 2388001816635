import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { OPEN_VIEW_PARAM } from '@howdy/models';

const AUTH_URL = 'https://auth.worklifebarometer.com';

@Injectable()
export class TokenService {

  rawToken: string;
  DataCenter: string;
  DataCenterApiEndPoint: string;
  Expiry: number;
  IsImpersonation: boolean;

  private AuthType: string;

  get authType() {
    return this.AuthType;
  }

  constructor(
    private cookie: CookieService,
    private jwtHelperService: JwtHelperService,
    private injector: Injector
  ) { }

  setToken(token: string) {
    const rawToken = token.replace(/\!/g, '.');
    const payload = this.jwtHelperService.decodeToken(rawToken);

    if (!!payload.IsImpersonation) {
      this.IsImpersonation = true;
    } else {
      const options: CookieOptions = { path: '/', sameSite: 'Lax', secure: true };
      this.cookie.set('AuthToken', rawToken, options);
    }

    this.rawToken = rawToken;
    this.DataCenter = payload.dc;
    this.DataCenterApiEndPoint = payload.api;
    this.AuthType = payload.apt;
    this.Expiry = payload.exp;

    return rawToken;
  }

  getToken() {
    if (this.rawToken != null && this.rawToken !== '') {
      return this.rawToken;
    } else {
      const sessionToken = this.jwtHelperService.tokenGetter();

      if (sessionToken) {
        const token = this.setToken(sessionToken);
        return token;
      } else {
        console.error('-------No Token help------');
      }
    }
  }

  readTokenFromURL() {
    const router = this.injector.get(Router);
    const jwtTokenService = this.injector.get(JwtHelperService);
    const cookieService = this.injector.get(CookieService);

    if (window.location.href.indexOf('token') > -1) {
      const token = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
      this.setToken(token);
      router.navigate(['pages/dashboard']);
    } else if (window.location.href.indexOf('managerfeedback') > -1) {
      this.clearToken();
      let href = window.location.href;
      const token = href.substr(href.lastIndexOf('/') + 1);
      href = href.substring(0, href.lastIndexOf('/'));
      this.setToken(token);
      const secretKey = href.substr(href.lastIndexOf('/') + 1);
      router.navigate([`forms/managerfeedback/${secretKey}`]);
    } else {
      const token = cookieService.get('AuthToken');
      if (!token || jwtTokenService.isTokenExpired(token)) {

        if (window.location.href.indexOf(OPEN_VIEW_PARAM) >= 0) {
          const paramsString = window.location.href.substr(window.location.href.lastIndexOf('?') + 1);
          const targetView = paramsString
            .split('&')
            .find(x => x.startsWith(OPEN_VIEW_PARAM))
            .split('=')[1];
          this.cookie.set(OPEN_VIEW_PARAM, targetView);
        }

        window.location.href = AUTH_URL;
      }
      this.setToken(token);
    }
  }

  clearToken() {
    this.rawToken = null;
    this.cookie.delete('AuthToken');
  }

  isValid() {
    const token = this.jwtHelperService.tokenGetter();
    const decodedToken = token && this.jwtHelperService.decodeToken(token);
    const containGuid = token && decodedToken && !!decodedToken.guid;
    const isExpired = this.jwtHelperService.isTokenExpired(token);

    return token && decodedToken && containGuid && !isExpired;
  }

}
