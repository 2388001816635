import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCodeToId, LanguageEnum } from '@howdy/models';


@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  dateTimePattern: string = 'EEEE {} d {} MMMM {} HH.mm';

  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = 'dd MMMM YYYY HH:mm'): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    const dateTime = datePipe.transform(value, pattern);

    if (pattern === this.dateTimePattern) {
      const languageId = LanguageCodeToId[this.translateService.currentLang];
      return this.replacePlaceholders(dateTime, languageId);
    }

    return dateTime;
  }

  getFormattedDateTime(date: any): string {
    const languageId = LanguageCodeToId[this.translateService.currentLang];
    const formattedDate = this.transform(date, this.dateTimePattern);

    return this.replacePlaceholders(formattedDate, languageId);
  }

  replacePlaceholders(formattedDate: string, languageId: number): string {
    if (languageId === LanguageEnum.English) {
      return formattedDate
        .replace('{}', 'the')
        .replace('{}', 'of')
        .replace('{}', 'at');
    } else if (languageId === LanguageEnum.Danish) {
      return formattedDate
        .replace('{}', 'den')
        .replace('{}', '')
        .replace('{}', 'klokken');
    } else {
      return formattedDate
        .replace('{}', 'den')
        .replace('{}', '')
        .replace('{}', 'kl');
    }
  }
}
