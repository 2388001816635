import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: { icon: 'chart-column', pack: 'solid', },
    link: '/pages/dashboard/overview',
    home: true,
  },
  {
    title: 'Loading...',
    link: '/pages/xyz',
    data: 'Portal.Modules.Company.Tabs.Info.ContactInfo' // AKA Translation
  }
];
