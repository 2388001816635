import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TokenService } from './token.service';
import { LoaderService } from './loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService,
    private loader: LoaderService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loader.show();
    // Injecting Token is Auth0 Job (didn't work!)
    if (req.url.startsWith('##API##')) {
      // if (!this.tokenService.isValid()) this.menuService.navigateHome();

      const token = this.tokenService.getToken();
      if (!token) {
        console.error('TOKEN Not Found');
        // window.location.href = this.configStore.getConfigStoreValues().GlobalAuth_SignIn;
      }
      const headers = req.headers.set('Authorization', `Bearer ${token}`);
      // console.log('Here is your token', token.substr(1, 5), 'for url ', req.url);

      req = req.clone({
        url: req.url.replace('##API##', this.tokenService.DataCenterApiEndPoint || 'No-URL-Provided-aM'),
        headers
      });
    }

    return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            console.log('Received unexpected http 401 unauthorized response from server');
            // this.tokenService.clearToken();
            // window.location.href = this.configStore.getConfigStoreValues().GlobalAuth_SignIn;
            if (this.tokenService.getToken() && this.tokenService.isValid) {
              return next.handle(req);
            }
          }
          return throwError(err);
        }),
        finalize(() => this.loader.hide())
      );
  }
}
