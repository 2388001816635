import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Employee, Manager } from '.';

export interface UserPreferences {
  activeCompanyId: number;
  activeResponseCenterId: number;
  activeEntityName: string;
  selectedLanguageId: string;
}

export interface UserProfile {
  ResponseCenters: ResponseCenter[];
  Companies: Company[];
  Me: IMe;
}

export interface CompanyCreationLanguages {
  Id: number;
  EnglishName: string;
  Code: string;
}

export interface CompanyCreationWizardData {
  Partners: Partner[];
  Languages: CompanyCreationLanguages[];
  DefaultResponseCenter: ResponseCenter[];
  BodyResponseCenter: ResponseCenter[];
  SpecialSurveyDefinition: SpecialSurveyDefinition[];
}

export interface SpecialSurveyDefinition {
  Id: number;
  Name: string;
}

export interface ResponseCenter {
  Id: number;
  Name: string;
  Mailbox: string;
  Hotline: string;
  NotifyViaEmail: boolean;
  NotifyViaSMS: boolean;
  type?: 'rc';
}

export interface ResponseCenterAgent {
  Id: number;
  Name: string;
  Count: number;
}

export interface EmailActivity {
  Value: number;
  Name: string;
  PersonRelated: boolean;
  UserRelated: boolean;
}

export interface IMe {
  Id: number;
  Locale: string;
  IanaTimeZone: string;
  TimeZone: string;
  Firstname: string;
  Lastname: string;
  Email: string;
  IsSystemAdministrator: boolean;
  Mobile: string;
  Raygun: string;
  Roles: Role[];
  UserType: UserTypeEnum;
  Locked?: boolean;
  MonthlyReport?: boolean;
  IsHowdyEmployee?: boolean;
  IsOwner?: boolean;
}

export interface Role {
  Id?: number;
  CompanyId: number;
  Features: any;
  ResponseCenterId: number;
  RoleType: number;
  Role: string;
  CompanyName: string;
  DepartmentId: number;
  DepartmentName: string;
  Type?: DepartmentTypeEnum;
  PrimaryContact?: boolean;
  ResponseCenterName?: string;
}

export enum UserTypeEnum {
  PortalUser = 'PortalUser',
  ApiUser = 'ApiUser'
}

export enum UserRoleEnum {
  SystemAdministrator = 1,
  HowdyEmployee = 2,
  PartnerAdministrator = 10,
  CompanyAdministrator = 20,
  DepartmentManager = 21,
  CompanyAdministratorReadOnly = 22,
  DepartmentManagerReadOnly = 23,
  ResponseCenterAdministrator = 50,
  ResponseCenterSupporter = 51,
  HRIntegration = 29,
  Observer = 30,
  NoActiveRole = -1
}

export interface CompanySettings {
  AllowExternalIntegration: boolean;
  AutoInviteNewEmployees: boolean;
  NotifyManagers: boolean;
  MaxDiffInEmployeeBatchUploads: number;
  ShowDepartmentRole: boolean;
  DepartmentDimension: string;
  TestAccess: boolean;
  CasesMinimumSize: number;
  ShowReportsModule: boolean;
  ShowServiceDisruption: boolean;
  FeedbackModuleEnabled: boolean;
  ManagerCoachingModuleEnabled: boolean;
  DisablePIIForManagers: boolean;
}
export interface Owner {
  AddOwner: boolean;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
};

export interface Partner {
  Id: number;
  Name: string;
}

export interface Product {
  Name: string;
  Code: string;
}

export interface CountryLanguage {
  Id: number;
  Name: string;
  Code: string;
}

export interface IndustryCode {
  Id: number;
  Name: string;
  Codes: string[] | string;
}

export interface Sales {
  Name: string;
}

export interface ResponseCenter {
  Id: number;
  Name: string;
}
export interface CompanyProductData {
  Products: string[];
  Partner: Partner;
  ResponseWellbeing: ResponseCenter;
  ResponseBody: ResponseCenter;
  NumberOfUsers: number;
  InfoResponseTeam: string;
  IsValid: boolean;
}

export interface Company {
  Address1?: string;
  Address2?: string;
  AllowIndependantContactWithHealthOffer?: boolean;
  AnonHealthOfferFromEmployer?: boolean;
  City?: string;
  Owner?: string | Owner;
  Country?: string;
  DefaultLanguageEnglishName?: string;
  DefaultLanguageName?: string;
  DefaultLanguageId?: number;
  DefaultResponseCenterId?: number;
  DefaultResponseCenterName?: string;
  BodyResponseCenterId?: number;
  BodyResponseCenterName?: string;
  Id?: number;
  MainIndustryCode?: number;
  IndustryCodeId?: number;
  IndustryCodeName?: string;
  Name?: string;
  PartnerId?: number;
  PartnerName?: string;
  StartDate?: moment.Moment | Date;
  ZipCode?: string;
  SalesTerritory?: string;
  ManagingDepartments?: CompanyDepartment[];
  AccountStatus?: number;
  type?: 'company';
  DepartmentDimensions?: DepartmentDimensions;
  Settings?: CompanySettings;
  Products?: string[];
  LicensesPurchased?: number;
  ResponseCenterBriefingUrl?: string;
}

export enum DepartmentTypeEnum {
  Department = 0,
  Division = 1,
  Project = 2
}

export type DepartmentDimensions = {
  [key in DepartmentTypeEnum]: string;
};

export interface CompanyDepartment {
  Id: number;
  CreatedOn?: moment.Moment | Date;
  CompanyId?: number;
  Division?: string;
  DivisionId?: number;
  Key?: string;
  ManagerName?: string;
  ManagerId?: number;
  Name: string;
  Size?: number;
  Type?: DepartmentTypeEnum;
  ParticipationThisMonth?: number;
  ParticipationCurrentMonth?: number;
  ParticipationLastMonth?: number;
  HasData?: boolean;
  HasUsers?: boolean;
  HasManagerCase?: boolean;
  HowdyScore?: number;
  HowdyScoreCurrentMonth?: number;
  HowdyScoreLastMonth?: number;
  IndexCurrentMonth?: number;
  IndexLastMonth?: number;
  StateActive?: number;
  StateActiveCurrentMonth?: number;
  StateActiveLastMonth?: number;
  StateInvited?: number;
  StateInvitedCurrentMonth?: number;
  StateInvitedLastMonth?: number;
  Users?: number;
  Managers?: Manager[];
}

export interface CompanyDimensions {
  [key: string]: CompanyDimension;
}

export interface CompanyDimensionsValues {
  [key: string]: {
    value: string;
    count: number;
  }[];
}

export interface CompanyDimension {
  Code: string;
  Label: string;
  AutocompleteSuggestions: DimensionSuggestion[];
  Required: boolean;
}

export interface DimensionSuggestion {
  Suggestion: string;
  Usage: number;
}

export interface IConfigStore {
  GlobalAuthSignIn: string;
  GlobalAuthSignOut: string;
  GlobalAuthPasswordReset: string;
}

export interface UserPermissions {
  hasOnlyDepartmentManagerRights: boolean;
  hasCompanyHRIntegrationRights: boolean;
  hasCompanyAdminRights: boolean;
  hasUserAdminRights: boolean;
  hasSystemAdminRights: boolean;
  hasReadOnlyRights: boolean;
  hasOnlyObserverRights: boolean;
  hasHowdyEmployeeRights: boolean;
  hasResponseCenterAdminRights: boolean;
  hasCompanyAdminReadonlyRights: boolean;
}

export interface AssignUserRole {
  userId: number;
  companyId: number;
  role: UserRoleEnum;
  departmentKey: string;
  departmentType: DepartmentTypeEnum;
  responseCenterId?: number;
  primaryContact?: boolean;
}

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export interface ActivityFlowStepDefinition {
  Id: number;
  Name: string;
}

export interface EmailTestReportModel {
  ActivityType: number;
  EmployeeId?: number;
  ManagerId?: number;
  CompanyId?: number;
  FlowDefinitionId?: number;
  IsTestTemplate: boolean;
  Content?: {
    AuthorId?: number;
    Text: string;
    RecipientIds: number[];
  };
}

export interface ParticipationStatsValues {
  TotalNumberOfEmployees?: number;
  ActiveEmployees?: number;
  InvitedEmployees?: number;
  AnsweredEmployees?: number;
  NotAnsweredEmployees?: number;
  ActiveUsers?: number;
  AllUsers?: number;
  InactiveUsers?: number;
  LoggedInWithin30Days?: number;
  NotLoggedInWithin30Days?: number;
}

export interface ParticipationStats {
  Label: string;
  Value: number;
}

export const OPEN_VIEW_PARAM = 'openView';
export enum OpenViewEnum {
  Notifications = 'notifications'
}

export interface Comment {
  Sender: Employee;
  Recipients: number[];
  Message: string;
}
