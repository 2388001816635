export enum ProductStateEnum {
  NotPurchased,
  Inactive,
  Active
}

export interface CompanyList {
  Id: number;
  Name: string;
  Products: {
    Body: ProductStateEnum;
    Manager: ProductStateEnum;
    InfoOffers: ProductStateEnum;
    Surveys: ProductStateEnum;
  };
  Licenses: number;
  LicensesDiff: number;
  StartDate?: string;
  Language?: number;
  DefaultResponseCenter?: string;
  BodyResponseCenter?: string;
  Calls?: number;
  SignUpLink?: string;
  Users?: string;
  Activation?: number;
  Answers?: number;
  BodyAnswers?: string;
  SurveyAnswers?: string;
  WellbeingCases?: number;
  BodyCases?: number;
};
