import * as cloneDeep from 'lodash.clonedeep';
import { BehaviorSubject } from 'rxjs';
import {
  CaseOutcomes,
  Company,
  CompanyDepartment,
  CompanyDimensions,
  CompanySettings,
  CompanySettingsDimension,
  DepartmentDropdownData,
  DepartmentMonthMetric,
  Effort,
  Heatmap,
  IMe,
  Reminder,
  Role,
  TargetPeriods,
  UserPreferences,
  UserProfile
} from '@howdy/models';
import { combineLatest, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from  'moment-timezone';
import { Moment } from 'moment';
import { LocalizedDatePipe } from '@howdy/shared/pipes/localized-date.pipe';
import { toTitleCase } from '@howdy/shared/utils/utils';

@Injectable()
export class SharedService {

  private _userProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(undefined);
  private _userPreferences$: BehaviorSubject<UserPreferences> = new BehaviorSubject<UserPreferences>(undefined);
  private _onEntityChange$: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>(undefined);
  private _onLanguageChange$: BehaviorSubject<UserPreferences> = new BehaviorSubject<UserPreferences>(undefined);
  private _globalDepartment$: BehaviorSubject<DepartmentDropdownData> = new BehaviorSubject(null);
  private _departmentMonthMetrics$: BehaviorSubject<DepartmentMonthMetric[]> = new BehaviorSubject(null);
  private selectedPeriodSubject = new BehaviorSubject<Moment>(null);
  private _effortsClick$: BehaviorSubject<void> = new BehaviorSubject(null);
  private reminderSubject$: BehaviorSubject<Reminder> = new BehaviorSubject<Reminder | null>(null);
  private _selectorPeriodType$: BehaviorSubject<TargetPeriods> = new BehaviorSubject<TargetPeriods>(TargetPeriods.Month);
  private _maxDateAvailable$: BehaviorSubject<Moment> = new BehaviorSubject<Moment>(moment());

  private _userProfile: UserProfile;
  private _userPreferences: UserPreferences;
  private _departmentMonthMetrics: DepartmentMonthMetric[];
  private _numberOfEfforts: number;
  private _minDateAvailable: Moment;
  private _currentEfforts: Effort[];

  constructor(
    private cookie: CookieService,
    private http: HttpClient,
    private translateService: TranslateService
  ) { }

  get currentEfforts() {
    return this._currentEfforts;
  }

  set efforts(currentEfforts) {
    this._currentEfforts = currentEfforts;
  }

  get reminder$() {
    return this.reminderSubject$.asObservable();
  }

  get effortsClick$(): Observable<void> {
    return this._effortsClick$.asObservable();
  }

  get selectedPeriod$(): Observable<Moment> {
    return this.selectedPeriodSubject;
  }

  set selectedPeriod(period: Moment) {
    this.selectedPeriodSubject.next(period);
  }

  get numberOfEfforts() {
    return this._numberOfEfforts ? this._numberOfEfforts : 0;
  }

  get selectorPeriodType$() {
    return this._selectorPeriodType$.asObservable();
  }

  get minDateAvailable() {
    return this._minDateAvailable;
  }

  get maxDateAvailable$() {
    return this._maxDateAvailable$.asObservable();
  }

  get globalDepartment$(): Observable<DepartmentDropdownData> {
    return this._globalDepartment$.asObservable().pipe(distinctUntilChanged());
  }

  get departmentMonthMetric$(): Observable<DepartmentMonthMetric[]> {
    return this._departmentMonthMetrics$.asObservable().pipe(filter(_ => !!_));
  }

  get userProfilePreferences$() {
    return combineLatest([this._userProfile$, this._userPreferences$])
      .pipe(filter(([userProfile, userPreferences]) => !!userProfile && !!userPreferences));
  }

  get userProfile$(): Observable<UserProfile> {
    return this._userProfile$.asObservable().pipe(filter(x => !!x));
  }

  get userPreferences$(): Observable<UserPreferences> {
    return this._userPreferences$.asObservable().pipe(filter(x => !!x));
  }

  get onEntityChange$(): Observable<Role[]> {
    return this._onEntityChange$.asObservable().pipe(filter(x => !!x));
  }

  get onLanguageChange$(): Observable<UserPreferences> {
    return this._onLanguageChange$.asObservable().pipe(filter(x => !!x));
  }

  get userProfile(): UserProfile {
    return cloneDeep(this._userProfile);
  }

  get userPreferences(): UserPreferences {
    return cloneDeep(this._userPreferences);
  }

  get currentEntityRoles(): Role[] {
    const entityTypeAttribute = this.userPreferences.activeCompanyId ? 'CompanyId' : 'ResponseCenterId';
    const entityId = this.userPreferences.activeCompanyId || this.userPreferences.activeResponseCenterId;
    return this.userProfile.Me.Roles.filter(role => role[entityTypeAttribute] === entityId);
  }

  get myDepartmentsInCompany(): CompanyDepartment[] {
    const companyId = this.userPreferences.activeCompanyId;
    return companyId ? this.userProfile.Companies.find(company => company.Id === companyId).ManagingDepartments || [] : [];
  }

  get currentCompany(): Company {
    const companyId = this.userPreferences?.activeCompanyId;
    return companyId && this.userProfile.Companies?.length > 0
      ? this.userProfile.Companies.find(company => company.Id === companyId)
      : null;
  }

  get Moment() {
    const locale = this.translateService.currentLang;

    moment.updateLocale(locale, {
      longDateFormat: {
        L: 'DD-MM-YYYY',
        l: 'D-M-YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
      }
    });

    return moment;
  }

  onEffortsClick() {
    return this._effortsClick$.next();
  }

  setSelectorPeriodType(periodType: TargetPeriods) {
    this._selectorPeriodType$.next(periodType);
  }

  setMinDateAvailable(date: Moment) {
    this._minDateAvailable = date;
  }

  setMaxDateAvailable(date: Moment) {
    this._maxDateAvailable$.next(date);
  }

  setNumberOfEfforts(numberOfEfforts: number) {
    this._numberOfEfforts = numberOfEfforts;
  }

  setReminder(reminder: Reminder): void {
    this.reminderSubject$.next(reminder);
  }

  setUserPreferences(newPreferences: UserPreferences) {
    const oldPreferences = this.userPreferences;

    this._userPreferences = newPreferences;
    this._userPreferences$.next(this.userPreferences);
    this.saveUserPreferencesToCookie();

    if (!oldPreferences || newPreferences.activeEntityName !== oldPreferences.activeEntityName) {
      this._onEntityChange$.next(this.currentEntityRoles);
    }

    if (!oldPreferences || newPreferences.selectedLanguageId !== oldPreferences.selectedLanguageId) {
      this._onLanguageChange$.next(this.userPreferences);
    }
  }

  saveUserPreferencesToCookie() {
    const userPreferencesString = JSON.stringify(this._userPreferences);
    this.cookie.set('UserPreferences', userPreferencesString, this.addDays(new Date(), 3650));
  }

  async setGlobalDepartment(department: DepartmentDropdownData) {
    this._globalDepartment$.next(department);
  }

  async getMeData() {

    // Note: disable cache to prevent login as impersonated user
    let headers = new HttpHeaders();
    headers = headers.set('Cache-Control', 'no-cache');

    const userPref = await this.getUserPreferencesFromCookie();
    const wlbHowdyCompanyId = 1005;
    const meData = this.http.get<IMe>(`##API##/userprofile/me`, { headers });
    const preloadedCompanyId = userPref?.activeCompanyId;
    const companiesR = preloadedCompanyId
      ? this.http.get<any[]>(`##API##/userprofile/me/companies?companyId=${preloadedCompanyId}`, { headers })
      : this.http.get<any[]>(`##API##/userprofile/me/companies?companyId=null`, { headers });
    const responseCentersR = this.http.get<any[]>(`##API##/userprofile/me/responsecenters`, { headers });

    const [me, companies, responseCenters] = await combineLatest([meData, companiesR, responseCentersR]).pipe(first()).toPromise();

    if (responseCenters.length !== 0) {
      responseCenters.unshift({
        Id: -1,
        Name: 'All Response Centers',
        Mailbox: '',
        Hotline: '',
        NotifyViaEmail: false,
        NotifyViaSMS: false,
        type: 'rc'
      });
    }

    const newProfile: UserProfile = {
      Me: me,
      Companies: companies,
      ResponseCenters: responseCenters
    };

    if (!companies.length) {
      const companiesRequest = this.http.get<any[]>(`##API##/userprofile/me/companies?companyId=null`, { headers });
      newProfile.Companies = await companiesRequest.pipe(first()).toPromise();
      userPref.activeCompanyId = null;
    }

    this._userProfile = newProfile;
    this._userProfile$.next(newProfile);

    if (userPref?.activeCompanyId) {
      await this.fetchCompanySettings(userPref.activeCompanyId);
    }

    moment.locale(me.Locale);
    moment.tz.setDefault(me.IanaTimeZone);

    const languageCode = me.Locale;

    userPref.selectedLanguageId = languageCode === 'en-gb' ? 'en' : languageCode;

    let companyFound = false;
    let company = null;
    let companyId = null;
    if (companies && companies.length > 0 && userPref.activeCompanyId
      && ((company = companies.find(c => c.Id === userPref.activeCompanyId)) != null)) {
      companyFound = true;
    }

    let responseCenterFound = false;
    let rc = null;
    if (responseCenters && responseCenters.length > 0 && userPref.activeResponseCenterId
      && ((rc = responseCenters.find(c => c.Id === userPref.activeResponseCenterId)) != null)) {
      responseCenterFound = true;
    }

    if (!companyFound && responseCenterFound) {
      userPref.activeCompanyId = null;
      userPref.activeEntityName = rc.Name;
    }

    if (companyFound && !responseCenterFound) {
      userPref.activeResponseCenterId = null;
      userPref.activeEntityName = company.Name;
    }

    if (!companyFound && !responseCenterFound && !newProfile.Me.IsSystemAdministrator && responseCenters.length > 0) {
      userPref.activeResponseCenterId = responseCenters[0].Id;
      userPref.activeCompanyId = null;
      userPref.activeEntityName = responseCenters[0].Name;
    } else {
      const activeCompanyId = !userPref.activeEntityName ? wlbHowdyCompanyId : userPref.activeCompanyId;
      companyId = newProfile.Me.IsSystemAdministrator ? activeCompanyId : newProfile.Companies[0].Id;

      await this.setActiveCompany(companyId, userPref, false);
    }

    if (companyFound && responseCenterFound) {
      userPref.activeResponseCenterId = responseCenters.length > 0 ? responseCenters[0].Id : null;
      userPref.activeEntityName = responseCenters[0].Name;
      userPref.activeCompanyId = null;
    }

    if (userPref.selectedLanguageId == null) {
      userPref.selectedLanguageId = 'en';
    }

    this.setUserPreferences(userPref);
    const hasRolesInMultipleCompanies = (me.Roles || [])
      .map(role => role.CompanyId)
      .filter(CompanyId => CompanyId !== null)
      .filter((value, index, self) => self.indexOf(value) === index)
      .length >= 2;
    const canAccessAllCompanies = me.IsSystemAdministrator || me.IsHowdyEmployee || hasRolesInMultipleCompanies;

    if (companies.length === 1 && canAccessAllCompanies && companyId) {
      this.getAllCompanies(companyId);
    }
  }

  async getAllCompanies(preloadedCompanyId: number) {
    // Note: disable cache to prevent login as impersonated user
    let headers = new HttpHeaders();
    headers = headers.set('Cache-Control', 'no-cache');

    const companiesR = this.http.get<any[]>(`##API##/userprofile/me/companies?companyId=null`, { headers });
    const companies = await companiesR.pipe(first()).toPromise();

    const languageCode = this._userProfile.Me.Locale.startsWith('en') ? 'en' : 'sv';
    const collator = new Intl.Collator(languageCode);
    const updatedProfile = {
      ...this._userProfile,
      Companies: [
        this.userProfile.Companies.find(x => x.Id === preloadedCompanyId),
        ...companies.filter(x => x.Id !== preloadedCompanyId)
      ].sort((a, b) => collator.compare(a.Name, b.Name))
    };

    this._userProfile = updatedProfile;
    this._userProfile$.next(updatedProfile);
  }

  async getUserPreferencesFromCookie() {
    const cookieString = this.cookie.get('UserPreferences');
    let userPrefVals = {} as UserPreferences;
    if (cookieString) {
      const prefCookie = JSON.parse(cookieString);
      userPrefVals = prefCookie;
      // Why We are having both we shouldn't ??
      if (prefCookie.activeResponseCenterId && prefCookie.activeCompanyId) {
        delete prefCookie.activeResponseCenterId;
        userPrefVals = prefCookie;
      }
    }

    moment.updateLocale(userPrefVals.selectedLanguageId || 'en', {
      longDateFormat: {
        L: 'DD-MM-YYYY',
        l: 'D-M-YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
      }
    });

    return userPrefVals;
  }

  addDays(date: Date, hours: number) {
    date.setHours(date.getDate() + hours);
    return date;
  }

  setLanguageLocalization(languageId: string) {
    const newPrefs = { ...this._userPreferences, selectedLanguageId: languageId };
    this.setUserPreferences(newPrefs);
    moment.updateLocale(languageId, {
      longDateFormat: {
        L: 'DD-MM-YYYY',
        l: 'D-M-YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
      }
    });
  }

  formatMonth(monthOfCalculation: Moment, showDays: boolean = true, format: string = 'MMM'): string {
    const now = moment();
    const isStartOfMonth = monthOfCalculation.isSame(now, 'day');
    const isCurrentMonth = now.isSame(monthOfCalculation, 'year') && now.isSame(monthOfCalculation, 'month');
    const yesterday = now.subtract(1, 'days');
    const datePipe = new LocalizedDatePipe(this.translateService);

    return isCurrentMonth && showDays && !isStartOfMonth
      ? yesterday.format('D. ') + toTitleCase(datePipe.transform(yesterday, 'MMM')).replace('.', '')
      : toTitleCase(datePipe.transform(monthOfCalculation, format)).replace('.', '');
  }

  async setActiveCompany(companyId: number, userPreferences: UserPreferences, updatePreferences = true) {
    const company = this._userProfile.Companies.find(x => x.Id === companyId);

    if (!company) {
      return;
    }

    await this.fetchCompanySettings(companyId);
    userPreferences.activeResponseCenterId = null;
    userPreferences.activeCompanyId = company.Id;
    userPreferences.activeEntityName = company.Name;

    if (updatePreferences) {
      this.setUserPreferences(userPreferences);
    }
  }

  getCompanyIntegrationStatus() {
    return this.userPreferences$.pipe(switchMap(
      ({ activeCompanyId }) =>
        this.http.get<CompanySettings>(`##API##/Company/${activeCompanyId}/Integration/Status`)), first());
  }

  getCompanyIntegrationTestAccessStatus() {
    return this.userPreferences$.pipe(switchMap(
      ({ activeCompanyId }) =>
        this.http.get<CompanySettings>(`##API##/Company/${activeCompanyId}/Integration/Status/User`)), first());
  }

  getCompanyDepartments(includeManagers: boolean = false, includeMetrics: boolean = false, id: number = null) {
    const companyId = !!id ? id : this._userPreferences.activeCompanyId;
    const options = { params: { includeManagers, includeMetrics } };
    return this.http.get<CompanyDepartment[]>(`##API##/Company/${companyId}/Department`, options);
  }

  getCompanyDimensions(id: number = null) {
    const companyId = !!id ? id : this._userPreferences.activeCompanyId;
    return this.http.get<CompanySettingsDimension[]>(`##API##/Company/${companyId}/Dimension`);
  }

  getCompanyEnabledLanguages() {
    return this.userPreferences$.pipe(
      filter(({ activeCompanyId, activeResponseCenterId }) => !!activeCompanyId && !activeResponseCenterId),
      switchMap(({ activeCompanyId }) => this.http.get<any[]>(`##API##/Company/${activeCompanyId}/Language/Enabled`)),
      first()
    );
  }

  getActiveDimensions() {
    const companyId = this._userPreferences.activeCompanyId;
    const langId = this.translateService.currentLang;
    return this.http.get<CompanyDimensions>(`##API##/Company/${companyId}/Dimension/Active/${langId}`);
  }

  getHeatmapData(departmentId: number = null) {
    const companyId = this._userPreferences.activeCompanyId;
    const params = { departmentId: `${departmentId}` };

    return this.http.get<Heatmap[]>(`##API##/Dashboard/Company/${companyId}/heatmap`, { params });
  }

  getCaseOutcomes() {
    const companyId = this._userPreferences.activeCompanyId;
    const languageId = this._userPreferences.selectedLanguageId;
    const params = { languageCode: languageId, companyId };

    return this.http.get<CaseOutcomes[]>(`##API##/Company/caseoutcomes`, { params });
  }

  private async fetchCompanySettings(companyId: number) {
    const company = this._userProfile.Companies.find(x => x.Id === companyId);

    if (company && !company.Settings) {
      company.Settings = await this.http.get<CompanySettings>(`##API##/Company/${companyId}/Integration/Settings`).toPromise();
    }
  }

  private fetchDepartmentMetrics(departmentId: number) {
    const companyId = this.userPreferences.activeCompanyId;
    const options = { params: { departmentId: `${departmentId}` } };
    return this.http.get<DepartmentMonthMetric[]>(`##API##/Dashboard/Company/${companyId}/metrics`, options)
      .pipe(map(metrics => metrics.map(metric => ({ ...metric, MonthStart: moment(metric.MonthStart) }))));
  }
}
