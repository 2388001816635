import { LanguageEnum } from '@howdy/models';

export interface Language {
  code: string;
  label: string;
  id?: number;
  flag?: string;
}

export const SupportedLanguages: Language[] = [
  {
    id: LanguageEnum.English,
    code: 'en',
    label: 'Portal.Shell.TopToolbar.UILanguages.En',
    flag: 'en'
  },
  {
    id: LanguageEnum.Swedish,
    code: 'sv',
    label: 'Portal.Shell.TopToolbar.UILanguages.Sv',
    flag: 'se'
  },
  {
    id: LanguageEnum.Danish,
    code: 'da',
    label: 'Portal.Shell.TopToolbar.UILanguages.Da',
    flag: 'da'
  }
];
