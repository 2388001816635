import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'token',
  template: `
  <h1>Test from TOKEN</h1>
  <router-outlet><router-outlet>
  `
})
export class TokenComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    const token = this.route.snapshot.paramMap.get('token');
    console.log(`We have TOKEN with value starts with ${token.substr(0, 6)}`);
    // TODO Un comment these lines
    // this.tokenSerivce.setToken(token);
    // const redirectedFromS = this.cookie.get('RedirectFrom');
    // const redirectedFrom = redirectedFromS != null && redirectedFromS !== '' ? JSON.parse(redirectedFromS) : null;

    // if (redirectedFrom && redirectedFrom.name && redirectedFrom.params) {
    //   this.cookie.delete('RedirectFrom');
    //   this.router.navigate([redirectedFrom.name], { queryParams: redirectedFrom.params });
    // }
    // else {
    //   this.nbMenuService.navigateHome();
    // }

  }

}
