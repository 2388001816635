import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from './token.service';

@Injectable()
export class ImpersonateService {

  get dataCenterCode() {
    return this.tokenService.DataCenter;
  }

  get isImpersonating() {
    return this.tokenService.IsImpersonation;
  }

  private _windowRef: Window;

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {
    this._windowRef = window;
  }

  impersonateUser(userId: number): void {
    const params = {
      id: userId.toString(),
      environment: this.dataCenterCode
    };

    this.httpClient.get<any>(`##API##/User/Impersonate`, { params }).subscribe(
      response => {
        const token = response.Result;
        this.openImpersonatedUserPage(token);
      }
    );
  }

  stopImpersonating() {
    if (this.isImpersonating) {
      this._windowRef.close();
    }
  }

  private openImpersonatedUserPage(token: string) {
    const origin = location.origin;
    const url = `${origin}/#/token/${token}`;

    this._windowRef.open(url, '_blank');
  }

}
