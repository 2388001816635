import { TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RaygunService } from './raygun.service';
import { Injector } from '@angular/core';

class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') { }

  getTranslation(lang: string): Observable<Object> {
    if (lang === 'none') {
      return of({ key: 'value' });
    } else {
      return this.http.get(`${this.prefix}${lang}${this.suffix}`);
    }
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new CustomLoader(http, '##API##/i18n/', '/');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {

  private reportedLabels: Set<string> = new Set<string>();

  constructor(private injector: Injector) { }

  handle(params: MissingTranslationHandlerParams) {
    const translateService = this.injector.get(TranslateService);
    const currentLang = translateService.currentLang;
    const cacheKey = `${params.key}/${params.translateService.currentLang}`;
    const blackList = ['SurveyDefinition', 'Reporting.Dimension'];

    const key = params.key;
    const keys = key.split('.');
    const isProduction = environment.production;
    const isValidKey = key.indexOf(' ') === -1 && keys.length > 1;
    const isCurrentLanguage = currentLang && translateService.translations[currentLang];
    const isCacheKey = this.reportedLabels.has(cacheKey);
    const isInBlackList = blackList.some(x => key.startsWith(x));
    const maxReportSizeCheck = this.reportedLabels.size < 10;

    if (
      isProduction &&
      isCurrentLanguage &&
      isValidKey &&
      !isCacheKey &&
      !isInBlackList &&
      maxReportSizeCheck
    ) {
      if (console) {
        console.log(key);
      }

      const error = new Error(`Label not found: '${key}' / '${params.translateService.currentLang}'`);
      const raygun = this.injector.get(RaygunService);

      this.reportedLabels.add(cacheKey);
      raygun.send(error);
    }

    return keys.slice(-1)[0];
  }
}

export const TranslateLabelsByEnviroment = {
  provide: TranslateLoader,
  useFactory: createTranslateLoader,
  deps: [HttpClient]
};
