import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { TokenComponent } from './pages/token/token.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: 'token/:token', component: TokenComponent },
  { path: 'pages', loadChildren: () => import('../app/pages/pages.module').then(m => m.PagesModule), canLoad: [AuthGuardService] },
  {
    path: 'responsecenter/incidents',
    loadChildren: () => import('../app/pages/manager-hotline/manager-hotline.module').then(m => m.ManagerHotlineModule),
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService]
  },
  { path: 'forms', loadChildren: () => import('../app/forms/howdy-forms.module').then(m => m.HowdyFormsModule) },
  { path: '', redirectTo: 'pages/dashboard', pathMatch: 'full' },
];

const config: ExtraOptions = {
  useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
