import { Injectable } from '@angular/core';
import { CompanySettings } from '@howdy/models';

// This Service to be Middleware to help moving Data from Component  to another one since we can't send it directly
@Injectable()
export class NavigationDataMiddlewareService {
  DimensionData: any[];
  EmployeeData: any[];
  CompanySettings: CompanySettings;
  constructor() {}
}
