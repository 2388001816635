import * as moment from 'moment';

export interface Employee {
    Birthday: string;
    CompanyId: number;
    ContactNumber: number;
    Dimensions: EmployeeDimensionValue;
    EmployedOn: moment.Moment | Date;
    EmployeeID: string;
    EmploymentStatus: PersonEmploymentStatus;
    ExternalId: string;
    FirstInvitationDate: moment.Moment | Date;
    Firstname: string;
    Fullname: string;
    Gender: number;
    HRContact: string;
    HealthOffer: string;
    HomeAddress1: string;
    HomeAddress2: string;
    HomeCity: string;
    HomeCountry: string;
    HomeZipCode: string;
    Id: number;
    JobTitle: string;
    LanguageId: number;
    Lastname: string;
    Locked: boolean;
    NotifyByEmail: boolean;
    NotifyByPush: boolean;
    NotifyBySMS: boolean;
    OptOut: boolean;
    ResponseCenterId: number;
    ResponseCenterName: string;
    SourcedFromExternalSystem: boolean;
    State?: EmployeeState;
    StateEffectiveFrom: string;
}

export interface EmployeeDimensionValue {
    [key: string]: string;
}

export enum DepartmentRole {
    Employee = 1,
    Manager = 2
}

export enum PersonEmploymentStatus {
  Employed = 0,
  OnLeave = 1
}

export enum EmployeeState {
  Created = 0,
  Invited = 1,
  Active = 2,
  OnLeave = 3,
  Removed = 99
}
