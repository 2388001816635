<div
  *ngIf="appLoaded && !!activeRequests.length"
  class="ngx-toastr toast-loader toast-info"
>
  <div class="toast-message">
    <div class="loading"></div>
    <div>Loading... </div>
  </div>
  <div class="requests">
    <div
      *ngFor="let request of finishedRequests"
      class="request finished"
    ></div>
    <div
      *ngFor="let request of activeRequests"
      class="request"
    ></div>
  </div>
</div>
