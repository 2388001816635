import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { Role, UserRoleEnum, UserPermissions } from '@howdy/models';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as cloneDeep from 'lodash.clonedeep';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  get hasOnlyDepartmentManagerRights$(): Observable<boolean> {
    return this._hasOnlyDepartmentManagerRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasCompanyHRIntegrationRights$(): Observable<boolean> {
    return this._hasCompanyHRIntegrationRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasCompanyAdminRights$(): Observable<boolean> {
    return this._hasCompanyAdminRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasCompanyAdminReadonlyRights$(): Observable<boolean> {
    return this._hasCompanyAdminReadonlyRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasUserAdminRights$(): Observable<boolean> {
    return this._hasUserAdminRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasSystemAdminRights$(): Observable<boolean> {
    return this._hasSystemAdminRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasReadOnlyRights$(): Observable<boolean> {
    return this._hasReadOnlyRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasOnlyObserverRights$(): Observable<boolean> {
    return this._hasOnlyObserverRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasHowdyEmployeeRights$(): Observable<boolean> {
    return this._hasHowdyEmployeeRights$.asObservable().pipe(filter(x => !!x));
  }

  get hasResponseCenterAdminRights$(): Observable<boolean> {
    return this._hasResponseCenterAdminRights$.asObservable().pipe(filter(x => !!x));
  }

  get userPermissions$(): Observable<UserPermissions> {
    return this._userPermissions$.asObservable().pipe(filter(x => !!x));
  }

  get userPermissions(): UserPermissions {
    return cloneDeep(this._userPermissions);
  }

  private _hasOnlyDepartmentManagerRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasCompanyHRIntegrationRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasCompanyAdminRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasUserAdminRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasSystemAdminRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasReadOnlyRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasOnlyObserverRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasHowdyEmployeeRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasResponseCenterAdminRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasCompanyAdminReadonlyRights$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _userPermissions$: BehaviorSubject<UserPermissions> = new BehaviorSubject(null);

  private roles: Role[];
  private _userPermissions: UserPermissions;

  constructor(private sharedService: SharedService) {
    this.sharedService.onEntityChange$.subscribe((roles: Role[]) => this.emitValues(roles));
  }

  hasOnlyDepartmentManagerRights(): boolean {
    return !!this.roles?.length &&
      this.roles.every(x => x.RoleType === UserRoleEnum.DepartmentManager || x.RoleType === UserRoleEnum.DepartmentManagerReadOnly);
  }

  hasOnlyObserverRights(): boolean {
    return !!this.roles?.length && this.roles.every(x => x.RoleType === UserRoleEnum.Observer);
  }

  hasReadOnlyRights(): boolean {
    const readOnlyRights: UserRoleEnum[] = [
      UserRoleEnum.CompanyAdministratorReadOnly,
      UserRoleEnum.DepartmentManagerReadOnly,
      UserRoleEnum.Observer
    ];

    return !!this.roles?.length && this.roles.every(x => readOnlyRights.includes(x.RoleType));
  }

  hasCompanyHRIntegrationRights(): boolean {
    const isHr = this.roles.some(role => role.RoleType === UserRoleEnum.HRIntegration);
    return isHr || this.hasSystemAdminRights();
  }

  hasCompanyAdminRights(): boolean {
    const isCompanyAdmin = this.roles.some(role => role.RoleType === UserRoleEnum.CompanyAdministrator);
    return isCompanyAdmin || this.hasSystemAdminRights();
  }

  hasCompanyAdminReadonlyRights(): boolean {
    const isCompanyReadonlyAdmin = this.roles.some(role => role.RoleType === UserRoleEnum.CompanyAdministratorReadOnly);
    return isCompanyReadonlyAdmin || this.hasSystemAdminRights();
  }

  hasUserAdminRights(): boolean {
    const isUserAdmin = this.roles.some(role =>
      role.RoleType === UserRoleEnum.PartnerAdministrator ||
      role.RoleType === UserRoleEnum.CompanyAdministrator ||
      role.RoleType === UserRoleEnum.ResponseCenterAdministrator);
    return isUserAdmin || this.hasSystemAdminRights();
  }

  hasSystemAdminRights(): boolean {
    return this.sharedService.userProfile.Me.IsSystemAdministrator;
  }

  hasHowdyEmployeeRights(): boolean {
    return this.sharedService.userProfile.Me.IsHowdyEmployee;
  }

  hasResponseCenterAdminRights(): boolean {
    const isResponseCenterAdmin = this.roles.some(role => role.RoleType === UserRoleEnum.ResponseCenterAdministrator);
    return isResponseCenterAdmin || this.hasSystemAdminRights();
  }

  hasPartnerAdminRights(): boolean {
    const isPartnerAdmin = this.roles.some(role => role.RoleType === UserRoleEnum.PartnerAdministrator);
    return isPartnerAdmin || this.hasSystemAdminRights();
  }

  hasAccessByRole(userRole: UserRoleEnum): boolean {
    switch (userRole) {
      case UserRoleEnum.SystemAdministrator:
        return this.hasSystemAdminRights();
      case UserRoleEnum.CompanyAdministrator:
        return this.hasCompanyAdminRights();
      case UserRoleEnum.DepartmentManager:
        return this.hasOnlyDepartmentManagerRights() || this.hasSystemAdminRights();
      case UserRoleEnum.HRIntegration:
        return this.hasCompanyHRIntegrationRights();
      case UserRoleEnum.ResponseCenterAdministrator:
        return this.hasResponseCenterAdminRights();
      case UserRoleEnum.PartnerAdministrator:
        return this.hasPartnerAdminRights();
      case UserRoleEnum.HowdyEmployee:
        return this.hasHowdyEmployeeRights();
      default:
        return false;
    }
  }

  hasAccess(userRoles: UserRoleEnum[]): boolean {

    if (!userRoles?.length) {
      return false;
    }

    return userRoles.some(userRole => this.hasAccessByRole(userRole));
  }

  private emitValues(roles: Role[]) {
    this.roles = roles;
    const permissionMethods = [
      'hasOnlyDepartmentManagerRights', 'hasCompanyHRIntegrationRights', 'hasCompanyAdminRights', 'hasCompanyAdminReadonlyRights',
      'hasUserAdminRights', 'hasSystemAdminRights', 'hasReadOnlyRights', 'hasOnlyObserverRights', 'hasHowdyEmployeeRights',
      'hasResponseCenterAdminRights'
    ];

    this._userPermissions = permissionMethods.reduce((permissions, permissionFnName) => {
      const permission = this[permissionFnName]();
      permissions[permissionFnName] = permission;
      this[`_${permissionFnName}$`].next(permission);
      return permissions;
    }, {} as UserPermissions);

    this._userPermissions$.next(this._userPermissions);
  }
}

export const BaseAccessRoles = [
  UserRoleEnum.Observer,
  UserRoleEnum.DepartmentManager,
  UserRoleEnum.CompanyAdministrator
];

export const HowdyUserAccessRoles = [
  UserRoleEnum.HowdyEmployee,
  UserRoleEnum.SystemAdministrator,
  UserRoleEnum.ResponseCenterSupporter
];
