<div class="modal-header">
  <button
    class="close"
    aria-label="Close"
    (click)="activeModal.close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <i class="fas fa-exclamation-triangle"></i>
  <h6 class="modal-body-title">{{ 'Portal.Modules.Dashboard.ServiceDisruption.Title' | translate }}</h6>
  <p class="modal-body-description">{{ 'Portal.Modules.Dashboard.ServiceDisruption.Description' | translate }}</p>
</div>
