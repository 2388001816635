
export function triggerDownload(json: any, name?: string) {
  const data = `text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(json))}`;
  const el = document.createElement('a');
  el.download = `${name || 'data'}.json`;
  el.href = `data:${data}`;
  el.click();
}

export function triggerDownloadCsv(csv: any, name?: string) {
  const data = `text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
  const el = document.createElement('a');
  el.download = `${name || 'data'}.csv`;
  el.href = `data:${data}`;
  el.click();
}

export function toTitleCase(s: string): string {
  return s && s.length > 1 ? s[0].toUpperCase() + s.substr(1) : s;
}

export function phraseToTitleCase(phrase: string): string {
  return !!phrase.length
    ? phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
    : phrase;
}

export function focusFirstInvalidElement(container: HTMLElement): boolean {
  const invalids: HTMLInputElement[] = Array.from(container.querySelectorAll(':not(form).ng-invalid'));
  const target: HTMLInputElement = invalids.filter((el: HTMLInputElement | HTMLSelectElement) => !el.disabled)[0] || null;
  if (target) {
    target.scrollIntoView({ behavior: 'smooth' });
    target.focus();
    return true;
  }
  return false;
}

export function capitalizeString(s: string): string {
  return typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : s;
}

export function capitalizeKeys(object: any): any {
  const validated = JSON.parse(JSON.stringify(object || null));
  if (Array.isArray(validated)) {
    return object.map(capitalizeKeys);
  }
  if (typeof validated === 'object' && !!object) {
    return Object.keys(object).reduce((res, key) => {
      res[capitalizeString(key)] = capitalizeKeys(object[key]);
      return res;
    }, {});
  }
  return object;
}

export function round(value: number, precision: number = 0) {
  const scale = Math.pow(10, Math.trunc(precision));
  return Math.round(value * scale) / scale;
}

export function splitCamelCaseString(s: string) {
  return s.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export class Counter {
  targetValue: number;
  currentValue: number;
  animationId: number;

  constructor() { }

  isRunning(): boolean {
    return this.targetValue !== this.currentValue;
  }

  stop(): void {
    cancelAnimationFrame(this.animationId);
    this.targetValue = this.currentValue = this.animationId = null;
  }

  updateWithAnimation(object: Object, property: string, newValue: number, precision: number = 0, stringify: boolean = false): void {
    if (+object[property] === newValue) {
      this.targetValue = this.currentValue = null;
      return;
    }

    if (newValue === null) {
      object[property] = null;
      return;
    }

    this.targetValue = newValue;
    this.currentValue = +object[property];
    const scale = Math.pow(10, precision);

    const numberOfSteps = Math.abs(newValue - object[property]) * scale;
    const step = numberOfSteps > 30 ? numberOfSteps / 30 / scale : 1 / scale;

    const updateValue = () => {
      const current = this.currentValue;
      const addition = current < newValue && Math.min(current + step, newValue);
      const subtraction = current > newValue && Math.max(current - step, newValue);

      this.currentValue = addition || subtraction;
      object[property] = stringify ? round(this.currentValue, precision).toFixed(precision) : round(this.currentValue, precision);

      if (+object[property] !== newValue && this.animationId) {
        this.animationId = requestAnimationFrame(updateValue);
      }
    };

    this.animationId = requestAnimationFrame(updateValue);
  }
}

export class ParallelCounter {
  counters: Counter[] = [];

  constructor() { }

  stopAll(): void {
    this.counters.forEach(counter => counter.stop());
    this.counters = [];
  }

  isAnyRunning(): boolean {
    return this.counters.some(counter => counter.isRunning());
  }

  updateWithAnimation(object: Object, property: string, newValue: number, precision: number = 0, stringify: boolean = false): void {
    const counter = new Counter();
    this.counters.push(counter);
    counter.updateWithAnimation(object, property, newValue, precision, stringify);
  }
}

export function getRandomColor() {
  const r = ('0' + ((Math.random() * 256) | 0).toString(16)).slice(-2);
  const g = ('0' + ((Math.random() * 256) | 0).toString(16)).slice(-2);
  const b = ('0' + ((Math.random() * 256) | 0).toString(16)).slice(-2);
  return '#' + r + g + b;
}

export function flatten(array: any[], depth = 1) {
  return depth > 0 ? array.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatten(val, depth - 1) : val), []) : array.slice();
}

export function objectsEquals(obj1: Object, obj2: Object): boolean {
  return Object.keys(obj1).every(key => {
    return obj2.hasOwnProperty(key) ?
      typeof obj1[key] === 'object' ? objectsEquals(obj1[key], obj2[key]) : obj1[key] === obj2[key] : false;
  });
}
